[class*="-scroll-"] {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 0.25rem;
	}
}

[class*="vertical-scroll-"] {
	&::-webkit-scrollbar {
		width: 10px;
	}
}

[class*="horizontal-scroll-"] {
	&::-webkit-scrollbar {
		height: 10px;
	}
}

[class*="-scroll-blue"] {
	&::-webkit-scrollbar-thumb {
		background-color: #483fa5;
	}
}

[class*="-scroll-orange"] {
	&::-webkit-scrollbar-thumb {
		background-color: #e18d5d;
	}
}

[class*="-scroll-dark"] {
	&::-webkit-scrollbar-thumb {
		background-color: rgb(56 61 77);
	}
}

[class*="-scroll-white"] {
	&::-webkit-scrollbar-thumb {
		background-color: #d9d9d9;
	}
}