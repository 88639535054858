a {
	text-decoration: none;

	&:link {
		text-decoration: none;
	}

	&:visited {
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}
}