.title-section {
	color: white;
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 20px;
	line-height: 28px;
}

.rounded-section {
	border-radius: $border-radius-section;
	overflow: hidden;
}
