.unselectable {
	-webkit-user-select: none;
	/* Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+/Edge */
	user-select: none;
	/* Standard */
}

.disabled-field {
	cursor: not-allowed !important;
	opacity: 0.5;

	&:hover {
		cursor: not-allowed !important;
	}

	.form-control:disabled {
		background-color: #2e323f !important;
	}
}

.disabled {
	opacity: 0.2 !important;
	cursor: unset !important;
}