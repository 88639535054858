// Backgrounds
$bg-color-navbar: #3203bd;
$bg-color-section-top: #25252d;
$bg-color-modal-header: #25252d;
$bg-color-room-section-btn: #5000cf;
$bg-color-default: #22222a;
$bg-color-modal: #262a35;
$bg-color-section: #2e3240;
$bg-color-sub-section: #373c4f;
$bg-color-toast: #3b3f4d;
$bg-color-checked: #51b2ff;
$bg-color-btn-red: #a50404;
$bg-color-nav-number: #ab0000;
$bg-color-green: #00ae75;

// Border radius
$border-radius-default: 1.5rem;
$border-radius-btn: 0.5rem;
$border-radius-icon-btn: 0.25rem;
$border-radius-section: 1rem;

// Breakpoints
$breakpoint-xsm: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$breakpoint-xxxl: 1600px;

// Colors
$color-modal-header-btn: white;
$color-text-blue: #51b2ff;
$color-text-gold: #ffcc00;
$color-text-red: #ff4040;
$color-text-green: #00ae75;
$color-invalid-feedback: red;
$color-selected: #51b2ff;
$color-transparent: #c3c3c3;

// Font weights
$font-weight-error-message: bold;

// Heights
$height-menu-top: 50px;
$height-btn: 35px;
$height-icon-btn: 24px;
$height-icon-input-btn: 16px;
$height-home-ads-vertical: 600px;
$height-home-ads-horizontal: 200px;
$height-page-header: 65px;

// Margins
$margin-between-sections: 30px;
$margin-between-activity-selection: 15px;

// Min heights
$min-height-footer: 30px;

// Paddings
$padding-btn: 5px;
$padding-page: 30px;
$padding-page-md: 20px;
$padding-page-sm: 10px;
$padding-modal: 30px;
$padding-modal-md: 20px;
$padding-modal-sm: 10px;

// Right
$right-modal-friends: 384px;
$right-modal-discussions: 335px;
$right-modal-notifications: 285px;
$right-modal-language: 233px;

// Widths
$width-menu-left-collapsed: 50px;
$width-menu-left-expanded: 200px;
$width-brand-collapsed: 0;
$width-brand-expanded: 170px;
$width-btn: 35px;
$width-icon-btn: 24px;
$width-icon-input-btn: 16px;
$width-home-ads: 180px;