em-emoji-picker {
	position: absolute;
	right: 65px;
	bottom: 46px;
	box-shadow: 2px 2px 11px 2px black !important;
	z-index: 20;

	@container (max-width: 520px) {
		width: 346px;
		right: calc(100% - 346px);
	}

	#root {
		background-color: $bg-color-modal !important;
	}
}