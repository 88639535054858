.modal {
	padding: 0 !important;

	.modal-content {
		border: 0;
		background-color: $bg-color-modal;
		padding: $padding-modal;

		.modal-header {
			padding: 0 0 15px 0;
			border-bottom: 1px solid darkgray;
			color: white;

			.btn-close {
				background-image: url('../../../public/img/close.png');
				opacity: inherit;
				background-position: center;
				background-size: 20px;

				&:hover {
					opacity: .7;
				}
			}
		}

		.modal-body {
			padding: 20px 0 0 0;
			color: white;
		}

		.modal-footer {
			border-top: none;
			margin: 20px 0 0 0;
			padding: 0;
			background-color: $bg-color-modal;

			button {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

.custom-modal {
	position: fixed;
	box-shadow: 2px 2px 11px 2px black;
	z-index: 3;
	display: block;
	margin: 0;
	border: 0px;
	border-radius: 0.5rem;
	z-index: 200;
	overflow: hidden;
	background-color: $bg-color-modal;
	padding: $padding-modal-sm;

	@media screen and (max-width: 900px) {
		width: 350px;
		height: 325px;
	}

	@media screen and (min-width: 900px) {
		width: 300px;
		height: 300px;
	}

	.title {
		border-top-left-radius: calc(0.5rem - 1px);
		border-top-right-radius: calc(0.5rem - 1px);
		color: $color-modal-header-btn;
		padding-left: 4px;
		padding-right: 2px;
		display: flex;
		align-items: center;
		height: 30px;
		margin-bottom: 5px;

		h1 {
			line-height: 40px;
			font-size: 20px;
			margin: 0;
			font-weight: bold;
			margin-right: auto;
			max-width: 208px;
			overflow: hidden;
		}

		button {
			border-radius: 0.375rem;
			margin-left: 6px;
			border: 0;
			color: white;
			background-color: transparent;

			@media screen and (max-width: 900px) {
				width: 30px;
				height: 30px;
				font-size: 18px;
			}

			@media screen and (min-width: 900px) {
				width: 28px;
				height: 28px;
				font-size: 14px;
			}

			svg {
				width: 20px;
				height: 20px;
			}

			&:hover {
				text-decoration: none;
				opacity: .75;
			}
		}
	}

	.content {
		width: 100%;
		height: calc(100% - 30px);
		position: relative;
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgb(56 61 77);
			border-radius: 25px;
		}
	}
}

.custom-modal-background {
	width: 100%;
	height: 100%;
	background-color: black;
	z-index: 10;
	top: 0;
	left: 0;
	position: fixed;
	opacity: 0.8;
}