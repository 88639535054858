@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import 'constants';
@import 'scss/main';

html,
body,
#root {
	width: 100%;
	height: 100%;
	display: flex;
	font-family: "Inter", sans-serif;
	background-color: $bg-color-default;
	display: flex;
	overflow: hidden;
}

.app {
	width: 100%;
	height: 100%;
	position: relative;
}

body {
	padding: 0 !important;
}

*:focus,
*:focus-visible {
	outline: 0 !important;
	box-shadow: none !important;
}
