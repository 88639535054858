.form-check {
	input {
		cursor: pointer;
	}
}

.form-check-input:checked {
	background-color: $bg-color-checked;
	border-color: $bg-color-checked;
}

.checkbox-dark {
	label {
		color: white;
	}

	input {
		width: 20px;
		height: 20px;
		border: 0;
		background-color: $bg-color-sub-section;
		color: white;

		&:hover {
			opacity: 0.7;
		}
	}
}