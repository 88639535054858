@import 'constants';

#loadingApp {
	width: 100%;
	height: 100%;
	flex-direction: row;
	align-items: center;
	padding-top: 72px;

	h2 {
		font-weight: 600;
		margin-bottom: 30px;
		text-align: center;
		color: white;
		@media screen and (max-width: 520px) { font-size: 24px; }
		@media screen and (min-width: 520px) and (max-width: 1312px) { font-size: 30px; }
		@media screen and (min-width: 1312px) { font-size: 35px;}
	}

  	.loading-app-section {	
		border-radius: 1.25rem;
		background-color: $bg-color-section;
		@media screen and (max-width: 520px) { width: 90%; padding: 30px; }
		@media screen and (min-width: 520px) and (max-width: 1312px) { width: 500px; padding: 40px; }
		@media screen and (min-width: 1312px) { width: 500px; padding: 50px; }
	}      
}

