select {
	padding-right: 40px !important;
	background-image:
		linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc) !important;
	background-position:
		calc(100% - 20px) calc(1em + 2px),
		calc(100% - 15px) calc(1em + 2px),
		calc(100% - 2.5em) 0.5em !important;
	background-size:
		5px 5px,
		5px 5px,
		1px 1.5em !important;
	background-repeat: no-repeat !important;

	&:hover {
		cursor: pointer;
	}
}

.select-dark {
	background-color: $bg-color-sub-section;
	color: lightgrey;
	transition: none;
	border: 0;

	&:focus,
	&:active,
	&:disabled,
	&:focus-visible {
		background-color: $bg-color-sub-section;
		color: white;
		border: none;
		outline: none;
	}

	&:disabled {
		background-color: $bg-color-sub-section;
		color: white;
		border-color: white;
		cursor: not-allowed !important;
		background-image: none !important;
	}
}