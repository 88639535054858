.input-btn {
	border-radius: $border-radius-btn;
	border: none;
	cursor: pointer;
	transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear, box-shadow 100ms linear;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
	border: 0;

	&:hover,
	&:active {
		opacity: 0.7;
	}

	svg {
		width: $width-icon-input-btn;
		height: $height-icon-input-btn;
	}
}

.input-dark,
.textarea-dark {
	background-color: $bg-color-sub-section;
	color: white;
	border: 0;

	&::placeholder {
		color: lightgrey;
		opacity: 0.4;
	}

	&:focus {
		background-color: $bg-color-sub-section;
		color: white;
		border-color: white;
	}

	&:disabled {
		background-color: $bg-color-sub-section;
		color: white;
		border-color: white;
		cursor: not-allowed !important;
		background-image: none !important;
		resize: none;
	}
}

.input-text-left {
	padding-right: 3px;
	color: lightgrey;
}

.input-text-dark {
	background-color: rgb(50 54 71);
	color: lightgrey;
	border: 1px solid rgb(50 54 71);
	padding: .375rem .75rem;
	border: 0;

	&:hover {
		opacity: 0.7;
	}
}

.input-spinner {
	pointer-events: none;
	cursor: not-allowed;
	height: 35px;

	.spinner-border {
		width: 16px;
		height: 16px;
		border-width: 3px;
	}
}

.input-success {
	pointer-events: none;
	cursor: not-allowed;
	color: $color-text-green;
}