.Toastify__toast {
	border: 1px solid rgb(46 50 64);
	border-radius: 0.75rem;
	background: $bg-color-toast;

	@media screen and (max-width: 480px) {
		margin-left: 15px;
		margin-right: 15px;
		margin-bottom: 15px;
	}

	.Toastify__toast-body {
		display: flex;
		flex-direction: row;

		.Toastify__close-button--light {
			opacity: 1;

			svg {
				height: 20px;
				width: 20px;
			}
		}
	}
}

.Toastify__toast--success {
	.Toastify__close-button--light svg {
		color: #4caf50;
	}

	.Toastify__toast-icon svg {
		fill: #4caf50;
	}
}

.Toastify__toast--info {
	.Toastify__close-button--light svg {
		color: $color-text-blue;
	}

	.Toastify__toast-icon svg {
		fill: $color-text-blue;
	}
}

.Toastify__toast--error {
	.Toastify__close-button--light svg {
		color: #f44336;
	}

	.Toastify__toast-icon svg {
		fill: #f44336;
	}
}