.page-section-table {
	width: 100%;
	background-color: $bg-color-section;
	padding: $margin-between-sections;
	border-radius: $border-radius-section;

	.buttons-top-table {
		width: 100%;
		margin-bottom: 10px;
		overflow: auto;

		button {
			margin-bottom: 5px;
			padding-left: 10px;
			padding-right: 10px;

			@media screen and (max-width: 700px) {
				width: 100% !important;
				margin-left: 0px !important;
				margin-right: 0px !important;
			}
		}

		.btn-100 {
			@media (max-width: 540px) {
				width: 100%;
			}
		}

		.btn-50 {
			@media (max-width: 540px) {
				width: calc(50% - 4px);
			}
		}
	}

	.ag-root-wrapper {
		margin-left: auto;
		margin-right: auto;
		height: 670px;
		width: 100%;
		border: none;
		background-color: $bg-color-section;

		.ag-header {
			background-color: $bg-color-section;
			border: none;

			.ag-header-cell {
				border: none;
				padding-left: 0;

				&::after {
					width: 0;
				}

				.ag-icon {
					color: white;
				}

				.ag-floating-filter-button, .ag-icon-filter {
					display: none;
				}

				.ag-header-cell-label  {
					justify-content: center;
				}
			}

			.ag-header-cell-text,
			.ag-icon-filter,
			.ag-icon-desc {
				color: white;
			}

			.ag-header-row {
				border-top: none;

				.ag-input-field-input {
					border-radius: $border-radius-btn;
					background-color: $bg-color-sub-section;
					color: white;
					color-scheme: dark;
					border: none;
					height: 20px;
					padding: 2px 10px;

					&::placeholder {
						color: lightgrey;
						opacity: 0.4;
					}
				}
			}
		}

		.ag-body-viewport {
			.ag-center-cols-viewport {
				height: 100% !important;
				background-color: $bg-color-section;
				height: 10px;

				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
				}

				&::-webkit-scrollbar {
					height: 13px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 0.25rem;
					background-color: $bg-color-sub-section;
				}

				.ag-center-cols-container {
					background-color: $bg-color-section;

					.ag-row {
						background-color: $bg-color-sub-section;
						color: white;
						border-color: #717171;
						border-radius: 0.5rem;
						height: 26px !important;
						border: none;

						.ag-cell {
							display: flex;
							align-items: center;
						}
					}
				}
			}
		}

		.ag-body-horizontal-scroll {
			display: none;
		}

		.ag-overlay {
			.ag-overlay-no-rows-center {
				color: white;
			}
		}

		.ag-paging-panel {
			background-color: $bg-color-section;
			color: white;
			border: none;

			.ag-icon {
				color: white;
			}
		}

		.ag-checkbox-input {
			cursor: pointer;
			background-color: $bg-color-sub-section;
		}

		.table-actions {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.btn-actions {
				width: 26px;
				height: 26px;

				svg {
					cursor: pointer;
					width: 20px;
					height: 20px;

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}

	.buttons-bottom-table {
		display: flex;
		justify-content: end;
		margin-top: 20px;

		.table-page {
			display: flex;
			margin: 0 10px;

			span {
				color: white;
				display: flex;
				align-items: center;
				margin-left: 10px;
				margin-right: 10px;
			}

			input {
				width: 50px;
				text-align: center;
				border-radius: 0.25rem;
			}
		}

		button {
			height: 24px;
			font-size: 12px;
			padding: 4px 12px;
		}
	}
}