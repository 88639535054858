.center {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.center-row {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

