.css-1fdsijx-ValueContainer {
	cursor: text;
}

.form-control.is-valid,
.was-validated .form-control:valid {
	border: 2px solid #3d3d3d;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
	border: 2px solid red;
	background-image: none !important;
}

.form-check-label {
	color: black;
}