[class*="btn-"] {
	border-radius: $border-radius-btn;
	border: none;
	cursor: pointer;
	transition: background-color 100ms linear, border-color 100ms linear, color 100ms linear, box-shadow 100ms linear;
	height: $height-btn;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: $padding-btn;
	font-size: 14px;
	font-weight: 600;

	&:hover,
	&:active {
		opacity: 0.7;
	}

	svg {
		width: $width-icon-btn;
		height: $height-icon-btn;
	}
}

.btn-blue {
	background: #116db7;
	color: white;

	&:hover,
	&:active {
		background: #116db7;
		color: white;
	}
}

.btn-grey {
	background-color: #3e4355;
	color: white;

	&:hover,
	&:active {
		background: #3e4355;
		color: white;
	}
}

.btn-dark-grey {
	background-color: $bg-color-section;
	color: white;

	&:hover,
	&:active {
		background: $bg-color-section;
		color: white;
	}
}

.btn-transparent {
	color: $color-transparent;
	background-color: transparent;

	&:hover {
		background-color: transparent;
		color: #f1f1ff;
	}
}

.btn-selected {
	background-color: transparent;
	color: $color-selected;
}

.btn-purple {
	padding: 5px;
	background-color: rgb(48 40 90) !important;
	color: white !important;

	&:hover {
		background-color: hsl(249deg 27% 33%) !important;
		color: white !important;
	}
}

.btn-pink {
	background: linear-gradient(180deg, rgb(181 49 201) 0%, rgb(162 58 180) 50%, rgb(181 49 201) 100%);
	color: white;

	&:hover {
		background-color: #bd65ff !important;
		color: white;
	}
}

.btn-white {
	background-color: white !important;
	color: black;

	&:hover {
		background: rgb(235, 235, 235) !important;
		color: black;
	}
}

.btn-black {
	background-color: rgba(0, 0, 0, 0.5) !important;
	color: white;
}

.btn-red {
	background-color: $bg-color-btn-red !important;
	color: white;

	&:hover {
		background: $bg-color-btn-red !important;
		color: white;
	}
}

.btn-green {
	background-color: rgb(50 179 0 / 80%) !important;
	color: white;

	&:hover {
		background: rgb(96 205 121) !important;
		color: white;
	}
}

.btn-yellow {
	background-color: rgb(251 185 27 / 80%) !important;
	color: white;

	&:hover {
		background: rgba(202, 147, 17, 0.8) !important;
		color: white;
	}
}

.btn-orange {
	background-color: orange !important;
	color: white;

	&:hover {
		background: rgba(202, 147, 17, 0.8) !important;
		color: white;
	}
}

.btn-border {
	border: 2px solid #9f9f9f;

	&:hover {
		background-color: hsla(0deg, 0%, 100%, 0.1);
	}
}

.disable-btn {
	opacity: 0.4 !important;
}